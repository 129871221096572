<template>
    <div id="Book">
        <section id="book-banner" class="banner-section-style">
            <div class="mobile-title hide">
                <h1 class="f20-mb">書籍のご案内</h1>
            </div>
            <div
                class="banner"
                :style="
                    addBackgroundImage(
                        require('@/assets/background/background-1.jpg'),
                        require('@/assets/background/background-1-mb.jpg')
                    )
                "
            ></div>
        </section>
        <section id="book-title" class="title-section-style hide-mb">
            <h2 class="title f29">書籍のご案内</h2>
        </section>
        <section id="book-main" class="w70 w85-mb">
            <article class="book-intro">
                <p class="f14 f14-mb">
                    購入をご希望の方は<router-link :to="{ name: 'Join' }"
                        >「お問い合わせフォーム」</router-link
                    >よりご連絡ください。
                </p>
            </article>
            <div
                class="book-group"
                v-for="(item, index) in books"
                :key="'book-' + index"
                :id="'book-' + index"
            >
                <div class="book-info-group serif w70-mb">
                    <aside>
                        <img v-lazy="item.imgUrl" :alt="item.title + 'image'" />
                        <a
                            :href="item.linkUrl"
                            target="_blank"
                            class="f14 f14-mb bold underline"
                            >Amazon.co.jpで詳細を見る</a
                        >
                    </aside>
                    <main>
                        <h2 class="f29 f22-mb">{{ item.title }}</h2>
                        <p
                            class="f14 f12-mb"
                            v-html="wrap(item.info.author)"
                        ></p>
                        <ul class="f14 f12-mb">
                            <li
                                v-for="(subitem, subindex) in item.info.table"
                                :key="'book-' + index + '-info-' + subindex"
                            >
                                <span class="head">{{ subitem.head }}</span>
                                <span class="body">{{ subitem.body }}</span>
                            </li>
                        </ul>
                        <router-link
                            :to="{
                                name: 'BookDetails',
                                params: {
                                    index: index,
                                },
                            }"
                            class="f12 f12-mb"
                            >この本の詳細を見る</router-link
                        >
                    </main>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { addBackgroundImage, wrap } from '@/utils/common.js'
import getBookData from '@/data/getBookData.js'
export default {
    name: 'Book',
    setup() {
        const { books } = getBookData()
        return {
            addBackgroundImage,
            wrap,
            books,
        }
    },
}
</script>
<style lang="scss" scoped>
.book-intro {
    text-align: center;
    a {
        color: $black;
    }
}
.book-group {
    padding: 3em 0;
    border-bottom: 1px solid $grayE;
    &:last-child {
        border-bottom: none;
    }
}
.book-info-group {
    aside {
        img {
            box-shadow: $shadow2;
        }
        a {
            display: block;
            text-align: center;
            line-height: 3em;
        }
    }
    main {
        flex: 1;
        h2 {
            border-bottom: 2px solid $grayE;
        }
        p,
        ul {
            margin: 1em 0;
            line-height: 1.5em;
        }
        ul {
            span {
                display: inline-block;
            }
            .head {
                width: 6em;
            }
        }

        a {
            @include solidButtonStyle();
            width: 200px;
        }
    }
}

@media screen and (min-width: 768px) {
    .book-info-group {
        display: flex;
        align-items: center;
        aside {
            margin-right: 3em;
            img {
                display: block;
                width: 100%;
                max-width: 200px;
            }
        }
    }
}
@media screen and(max-width:767px) {
    .book-intro {
        margin-top: 50px;
    }
    .book-info-group {
        aside {
            text-align: center;
            img {
                max-width: 60%;
            }
        }
        main {
            margin: 2em 1em 5em;
            a {
                margin: 30px auto;
            }
        }
    }
}
</style>
